import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Header from './components/header';
import Footer from './components/footer';
import Landing from './pages/Landing';
import Team from './pages/Team';
import Sponsors from './pages/Sponsorship';
import Speakers from './pages/Speakers';
import Schedule from './pages/Schedule'
import Construction from './pages/Construction';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/*<Route path="/sponsors" element={<Construction />} />*/}
          {/*<Route path="/team" element={<Construction />} />*/}
          <Route path="/speakers" element={<Construction />} />
          <Route path="/schedule" element={<Construction />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
