import './style.css';
import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import img from '../../media/THD.png';
import { useState, useEffect, useRef } from 'react';
const Header = () => {
    const location = useLocation();
    const [homeStyle, setHomeStyle] = useState('font-bold');
    const [sponStyle, setSponStyle] = useState('hover:underline');
    const [teamStyle, setTeamStyle] = useState('hover:underline');
    const [speakerStyle, setSpeakerStyle] = useState("hover:underline");
    const [scheduleStyle, setScheduleStyle] = useState("hover:underline");
    const [headerHeight, setHeaderHeight] = useState('h-[12.0625rem]');
    const [iteration, setIteration] = useState(0);
    const [dropdown, setDropdown] = useState('closed');
    const summaryRef = useRef(null);
  
    const [width, setWidth] = useState(window.innerWidth);

    const openDetails = () => {
      if (summaryRef.current) {
        summaryRef.current.click();
      }
    };

    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      if (width >= 1300 && dropdown === 'open'){
          openDetails();
        }
    }, [width]);

    useEffect(() => {
        const currentPath = location.pathname;
        switch (currentPath) {
            case '/':
                setHomeStyle('font-bold');
                setSponStyle('min-[1300px]:hover:underline');
                setTeamStyle('min-[1300px]:hover:underline');
                setSpeakerStyle("min-[1300px]:hover:underline");
                setScheduleStyle("min-[1300px]:hover:underline");
                break;
            case '/team':
                setHomeStyle('min-[1300px]:hover:underline');
                setSponStyle('min-[1300px]:hover:underline');
                setTeamStyle("font-bold");
                setSpeakerStyle("min-[1300px]:hover:underline");
                setScheduleStyle("min-[1300px]:hover:underline");
                break;
            case '/sponsors':
                setHomeStyle('min-[1300px]:hover:underline');
                setSponStyle('font-bold');
                setTeamStyle('min-[1300px]:hover:underline');
                setSpeakerStyle("min-[1300px]:hover:underline");
                setScheduleStyle("min-[1300px]:hover:underline");
                break;
            case '/speakers':
                setHomeStyle('min-[1300px]:hover:underline');
                setSponStyle('min-[1300px]:hover:underline');
                setTeamStyle('min-[1300px]:hover:underline');
                setSpeakerStyle("font-bold");
                setScheduleStyle("min-[1300px]:hover:underline");
                break;
            case '/schedule':
                setHomeStyle('min-[1300px]:hover:underline');
                setSponStyle('min-[1300px]:hover:underline');
                setTeamStyle('min-[1300px]:hover:underline');
                setSpeakerStyle("min-[1300px]:hover:underline");
                setScheduleStyle("font-bold");
                break;
            default:
                setHomeStyle('font-bold');
                setSponStyle('min-[1300px]:hover:underline');
                setTeamStyle('min-[1300px]:hover:underline');
                setSpeakerStyle("min-[1300px]:hover:underline");
                setScheduleStyle("min-[1300px]:hover:underline");
        }

    }, [location.pathname]);

    return (
      <header
        className={
          "flex flex-row bg-[#FFF] min-[1300px]:h-[12.0625rem] " + headerHeight
        }
      >
        <div className="flex flex-row mt-[2.5rem] small:mt-[1.125rem] lg:mt-[2.375rem] w-[7.875rem] h-[3.0625rem] small:h-[5.625rem] small:w-[16.125rem] lg:h-[6.3125rem] absolute left-[1.94rem] small:left-[2.3125rem]">
          <img
            src={img}
            alt="logo"
            className="h-[3.0625rem] w-[3.0625rem] small:h-[5.625rem] small:w-[5.40625rem] lg:h-[6.3125rem] lg:w-[6.3125rem]"
          />
          <div className="flex flex-col w-[4.8125rem] h-[1.875rem] lg:h-[3.75rem] mt-[1.0625rem] mb-[0.125rem] lg:mt-[2.1875rem] lg:mb-[0.375rem] flex-1 small:mt-[1.8rem]">
            <p className="font-[Poppins] text-[0.625rem] small:text-[1.25rem] font-[500]">
              National Health
            </p>
            <p className="font-[Poppins] text-[0.625rem] small:text-[1.25rem] font-[500]">
              Datathon
            </p>
          </div>
        </div>
        <div className="mt-[5.5625rem] flex-row gap-[2rem] flex-1 xl:gap-[4.8125rem] hidden min-[1300px]:flex absolute right-[8.75rem]">
          <div
            className={
              "h-[1.6875rem] font-400 font-[Poppins] text-[1.125rem] " +
              homeStyle
            }
          >
            <Link to="/"> Home</Link>
          </div>
          {/*<div*/}
          {/*  className={*/}
          {/*    "h-[1.6875rem] font-400 font-[Poppins] text-[1.125rem] flex-1 flex justify-center items-center " +*/}
          {/*    teamStyle*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Link to="/team"> Our Team</Link>*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className={*/}
          {/*    "h-[1.6875rem] font-400 font-[Poppins] text-[1.125rem] " +*/}
          {/*    sponStyle*/}
          {/*  }*/}
          {/*>*/}
          {/*  <Link to="/sponsors"> Sponsors</Link>*/}
          {/*</div>*/}
          <div
            className={
              "h-[1.6875rem] font-400 font-[Poppins] text-[1.125rem] " +
              speakerStyle
            }
          >
            <Link to="/speakers">Speakers</Link>
          </div>
          <div
            className={
              "h-[1.6875rem] font-400 font-[Poppins] text-[1.125rem] " +
              scheduleStyle
            }
          >
            <Link to="/schedule">Schedule</Link>
          </div>
        </div>

        <details className="mt-[4rem] w-[100%] h-[1rem] min-[1300px]:hidden">
          <summary
            className="ease-in-out duration-[0.5s] transition-[background-image] cursor-pointer bg-[url('./media/lines.svg')] w-[3rem] h-[1.125rem] list-none absolute right-[1.875rem] bg-cover"
            id={dropdown}
            onClick={() => {
              if (iteration % 2 === 0) {
                setHeaderHeight("h-[58.0625rem]");
                setDropdown("open");
              } else {
                setHeaderHeight("h-[12.0625rem]");
                setDropdown("closed");
              }
              setIteration(iteration + 1);
            }}
            ref={summaryRef}
          ></summary>

          <div className="bg-[#DF4440] w-[100%] h-[38.5625rem] mt-[3.6875rem] flex flex-col gap-[0.9375rem] lg:mt-[5rem]">
            <Link to="/" onClick={() => openDetails()}>
              <div className="ml-[1.375rem] mr-[1.875rem] mt-[3.5rem] hover:bg-[#FF6965] hover:border-[#FF6965] hover:rounded-[0.625rem] py-[0.625rem]">
                <p className={"font-[Poppins] text-white text-[1rem] ml-[0.5625rem] " + homeStyle}>
                  Home
                </p>
              </div>
            </Link>

            <Link to="/team" onClick={() => openDetails()}>
              <div className="ml-[1.375rem] mr-[1.875rem] hover:bg-[#FF6965] hover:border-[#FF6965] hover:rounded-[0.625rem] py-[0.625rem]">
                <p className={"font-[Poppins] text-white text-[1rem] ml-[0.5625rem] " + teamStyle}>
                  Our Team
                </p>
              </div>
            </Link>

            <Link to="/sponsors" onClick={() => openDetails()}>
              <div className="ml-[1.375rem] mr-[1.875rem] hover:bg-[#FF6965] hover:border-[#FF6965] hover:rounded-[0.625rem] py-[0.625rem]">
                <p className={"font-[Poppins] text-white text-[1rem] ml-[0.5625rem] " + sponStyle}>
                  Our Sponsors
                </p>
              </div>
            </Link>

            <Link to="/speakers" onClick={() => openDetails()}>
              <div className="ml-[1.375rem] mr-[1.875rem] hover:bg-[#FF6965] hover:border-[#FF6965] hover:rounded-[0.625rem] py-[0.625rem]">
                <p className={"font-[Poppins] text-white text-[1rem] ml-[0.5625rem]  " + speakerStyle}>
                  Our Speakers
                </p>
              </div>
            </Link>

            <Link to="/schedule" onClick={() => openDetails()}>
              <div className="ml-[1.375rem] mr-[1.875rem] hover:bg-[#FF6965] hover:border-[#FF6965] hover:rounded-[0.625rem] py-[0.625rem]">
                <p className={"font-[Poppins] text-white text-[1rem] ml-[0.5625rem]  " + scheduleStyle}>
                  Our Schedule
                </p>
              </div>
            </Link>
          </div>
        </details>
      </header>
    );
};

export default Header;